<template>
  <div>
    <div v-if="!loader" class="ma_licence__loading-container">
      <Loading :loading="true" />
    </div>
    <div v-if="modalIsOpen" @click:outside="close()" max-width="80%">
      <!-- <v-tabs background-color="blue-dark" dark grow>
        <v-tabs-slider></v-tabs-slider>

        <v-tab style="display:flex;flex-direction:column;">
          <span style="font-size:1.2rem;">
            Fusionner
          </span>
        </v-tab>
      </v-tabs> -->

      <div class="modal__content" v-if="mounted">
        <v-tabs-items>
          <v-row>
            <v-col md="12" lg="5">
              <v-card
                class="mainCard m-3"
                @click.prevent="selectProfil(1, firstUserFullData)"
              >
                <v-card-title
                  class="mainCardTitle w-100 m-0"
                  v-if="selectFirstCard"
                >
                  <v-row>
                    <v-col cols="4">
                      <Avatar
                        :context="{
                          ID_Utilisateur: iduserFirst,
                        }"
                        size="64"
                        :key="iduserFirst"
                      />
                    </v-col>
                    <v-col
                      class="d-flex justify-content-center align-items-center"
                      cols="8"
                    >
                      <p class="ml-3 cardTitle-text">
                        {{ firstUserFullData.UTI_Utilisateurs[0].CT_Nom }}
                        {{ firstUserFullData.UTI_Utilisateurs[0].CT_Prenom }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-title
                  class="mainCardTitle w-100 not-selected m-0"
                  v-if="nonSelectFirstCard"
                >
                  <v-row>
                    <v-col cols="4">
                      <Avatar
                        :context="{
                          ID_Utilisateur: iduserFirst,
                        }"
                        size="64"
                        :key="iduserFirst"
                      />
                    </v-col>
                    <v-col
                      class="d-flex justify-content-center align-items-center"
                      cols="8"
                    >
                      <p class="text-primary ml-3">
                        {{ firstUserFullData.UTI_Utilisateurs[0].CT_Nom }}
                        {{ firstUserFullData.UTI_Utilisateurs[0].CT_Prenom }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-list class="transparent w-100">
                  <v-list-item v-if="dateNaissanceFirstUser">
                    <v-list-item-title class="list_item_card"
                      >Date de naissance</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      dateNaissanceFirstUser
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="canDisplayPhoneNumber">
                    <v-list-item-title class="list_item_card"
                      >Téléphone portable</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      firstUserFullData.UTI_Utilisateurs[0].CT_TelephoneMobile
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="firstUserFullData.UTI_Utilisateurs[0].CT_Email"
                  >
                    <v-list-item-title class="list_item_card"
                      >Adresse email</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      firstUserFullData.UTI_Utilisateurs[0].CT_Email
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="firstUserFullData.UTI_Utilisateurs[0].LicenceNumero"
                  >
                    <v-list-item-title class="list_item_card"
                      >Numéro de licence</v-list-item-title
                    >
                    <v-list-item-title
                      ><v-chip outlined color="blue" small>
                        {{
                          firstUserFullData.UTI_Utilisateurs[0].LicenceNumero
                        }}</v-chip
                      ></v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item v-if="firstUserStructureName">
                    <v-list-item-title class="list_item_card"
                      >Club</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      firstUserStructureName
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="slected_profil m-3" v-if="selectFirstCard">
                <p>Profil sélectionné<v-icon color="#082B6E" class="ml-1">check</v-icon></p>
              </div>
              <div class="unselected_profil m-3" v-if="selectSecondCard">
                <p>Profil non sélectionné</p>
              </div>
            </v-col>
            <v-col
              class="d-flex justify-content-center align-items-center"
              md="12"
              lg="2"
            >
              <div class="swapIcon">
                <v-icon size="18">mdi-swap-horizontal-bold</v-icon>
                <span>Fusionner les doublons</span>
              </div>
            </v-col>
            <v-col md="12" lg="5">
              <v-card
                class="mainCard m-3"
                @click.prevent="selectProfil(2, selectedUserFullData)"
              >
                <v-card-title
                  class="mainCardTitle w-100 m-0"
                  v-if="selectSecondCard"
                >
                  <v-row>
                    <v-col cols="4">
                      <Avatar
                        :context="{
                          ID_Utilisateur: iduserSecond,
                        }"
                        size="64"
                        :key="iduserSecond"
                      />
                    </v-col>
                    <v-col
                      class="d-flex justify-content-center align-items-center"
                      cols="8"
                    >
                      <p class="ml-3 cardTitle-text">
                        {{ selectedUserFullData.UTI_Utilisateurs[0].CT_Nom }}
                        {{ selectedUserFullData.UTI_Utilisateurs[0].CT_Prenom }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-title
                  class="mainCardTitle w-100 not-selected m-0"
                  v-if="nonSelectSecondCard"
                >
                  <v-row>
                    <v-col cols="4">
                      <Avatar
                        :context="{
                          ID_Utilisateur: iduserSecond,
                        }"
                        size="64"
                        :key="iduserSecond"
                      />
                    </v-col>
                    <v-col
                      class="d-flex justify-content-center align-items-center"
                      cols="8"
                    >
                      <p class="text-primary ml-3">
                        {{ selectedUserFullData.UTI_Utilisateurs[0].CT_Nom }}
                        {{ selectedUserFullData.UTI_Utilisateurs[0].CT_Prenom }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-list class="transparent w-100">
                  <v-list-item v-if="dateNaissanceUserSelected">
                    <v-list-item-title class="list_item_card"
                      >Date de naissance</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      dateNaissanceUserSelected
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="canDisplayPhoneNumber">
                    <v-list-item-title class="list_item_card"
                      >Téléphone portable</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      selectedUserFullData.UTI_Utilisateurs[0]
                        .CT_TelephoneMobile
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedUserFullData.UTI_Utilisateurs[0].CT_Email"
                  >
                    <v-list-item-title class="list_item_card"
                      >Adresse email</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      selectedUserFullData.UTI_Utilisateurs[0].CT_Email
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      selectedUserFullData.UTI_Utilisateurs[0].LicenceNumero
                    "
                  >
                    <v-list-item-title class="list_item_card"
                      >Numéro de licence</v-list-item-title
                    >
                    <v-list-item-title
                      ><v-chip outlined color="blue" small>
                        {{
                          selectedUserFullData.UTI_Utilisateurs[0].LicenceNumero
                        }}</v-chip
                      ></v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item v-if="selectedUserStructure.NomStructure">
                    <v-list-item-title class="list_item_card"
                      >Club</v-list-item-title
                    >
                    <v-list-item-title class="list_item_card_value">{{
                      selectedUserStructure.NomStructure
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="slected_profil m-3" v-if="selectSecondCard">
                <p>Profil sélectionné<v-icon color="#082B6E" class="ml-1">check</v-icon></p>
              </div>
              <div class="unselected_profil m-3" v-if="selectFirstCard">
                <p>Profil non sélectionné</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card flat>
                <p class="ml-3 recap">
                  Récapitulatif de l'action:
                </p>
                <ul class="recap-list">
                  <li>
                    Le numéro de licence conservé suite à l'opération :
                    <span class="font-weight-bold">{{
                      licenceNumeroSelected
                    }}</span>
                  </li>
                  <li>
                    L"ensemble de l'historique de l'adhérent sera conservé
                    (compétition, données personnelles...)
                  </li>
                </ul>

                <div class="ml-2">
                  <v-alert
                    type="error"
                    dense
                    border="left"
                    colored-border
                    color="red lighten-2"
                    class="attention"
                  >
                    Attention : La fusion de ces 2 fiches est irréversible.
                  </v-alert>
                </div>
              </v-card>
              <div class="my-5 ml-2">
                <v-btn class="btn btn-primary mr-3" @click.prevent="fusionData"
                  >Confirmer la fusion des deux adhérents</v-btn
                >

                <v-btn
                  class="btn btn-primary mr-3"
                  rounded
                  large
                  @click="close"
                >
                  Annuler l'action
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Common/Common__Avatar.vue";
import Loading from "@/components/LoadingIndicator.vue";

import { mapActions, mapGetters } from "vuex";

import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
// import { use } from "vue/types/umd";

// import { sleep } from "@/plugins/utils";

export default {
  components: { Avatar, Loading },

  data: () => ({
    loading: false,
    mounted: false,
    firstUserFullData: {},
    loader: false,
    selectedUserFullData: {},
    selectFirstCard: true,
    nonSelectFirstCard: false,
    selectSecondCard: false,
    nonSelectSecondCard: true,
    firstuser: {},
    seconduser: {},
    licenceNumeroSelected: "",
    firstUserIsOlder: true,
    dateNaissanceFirstUser: "",
    dateNaissanceUserSelected: "",
    iduserFirst: "",
    iduserSecond: "",
    principalUser: {},
  }),

  async created() {
    this.mounted = true;
    await this.setData();
    console.log("userSelected", this.userSelected);
    console.log("context merge duplicate", this.context);
    console.log("firstCurrentUser merge duplicate", this.firstCurrentUser);
  },

  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),

    firstUserStructure() {
      if (this.firstCurrentUser) {
        return this.firstCurrentUser.STR_Structure;
      }
      return null;
    },

    firstUserStructureName() {
      if (this.firstCurrentUser.licences[0].product.slug.includes('hors_club')){
        return 'Licence HORS CLUB';
      }

      return this.firstUserStructure.NomStructure;
    },

    selectedUserStructure() {
      if (this.userSelected) {
        return this.userSelected.structure;
      }
      return null;
    },
    canDisplayPhoneNumber() {
      if (
        this.selectedUserFullData?.UTI_Utilisateurs?.[0]?.CT_TelephoneMobile &&
        this.firstUserFullData?.UTI_Utilisateurs?.[0]?.CT_TelephoneMobile
      )
        return true;
      return false;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUtilisateurFullData",
      "updateImmediatlyUser",
      "updateStructureUtilisateur",
      "deleteUtilisateur",
      "updateFluxFinancierUser",
      "updateDemandeModificationUser",
      "updateLicenceAssuranceUser",
      "updateNewsletterUser",
      "updatePersonnesAPrevenirUser",
      "updateLicenceuser",
      "updateActivitePratiqueeUser",
      "updateStructureUtilisateurModuleUser",
      "updateAdresseUser",
    ]),
    // ...mapActions("finances", ["updateFluxFinancierUser"]),
    ...mapActions("structure", [
      "updateGroupeSaisonCreneauUser",
      "updateStructureUtilisateurUser",
    ]),
    ...mapActions("voyage", ["updateAssuranceVoyageUser"]),

    async setData() {
      let firstUser = await this.getUtilisateurFullData(
        this.firstCurrentUser.ID_Utilisateur
      );

      this.principalUser = firstUser;
      this.firstUserFullData = firstUser;
      this.firstuser = firstUser;
      this.licenceNumeroSelected = firstUser.UTI_Utilisateurs[0].LicenceNumero;

      this.dateNaissanceFirstUser = this.$moment(
        firstUser.UTI_Utilisateurs[0].DN_DateNaissance
      ).format("DD/MM/YYYY");

      this.iduserFirst = firstUser.UTI_Utilisateurs[0].id;
    },

    close() {
      // this.$emit("onClose");
      this.modalIsOpen = false;
    },

    async fusionData() {
      const selectedUser = this.seconduser.UTI_Utilisateurs[0];
      const firstUser = this.firstuser.UTI_Utilisateurs[0];
      const idDeleted = selectedUser.id;
      try {
        const response = await this.axios.patch(
            process.env.VUE_APP_FFME_BACK_LARAVEL_MS_MEMBER_URL + "/fuses",
            {
              "to_keep": firstUser.id,
              "to_fuse": idDeleted,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
              }
            }
        );
        const json = await response.data;
        success_notification(json.message);
        this.close();
      } catch (error) {
        error_notification(error.response.data.message + '\n' + error?.response?.data?.errors?.Season);
      }
    },

    selectProfil(idSelected, principalUser) {
      const selectedUser = this.seconduser;
      const firstUser = this.firstuser;

      console.log("principalUser", principalUser);

      const selectedPrincipalLicence =
        principalUser.UTI_Utilisateurs[0].LicenceNumero;
      console.log("selectedPrincipalLicence", selectedPrincipalLicence);

      if (this.licenceNumeroSelected != selectedPrincipalLicence) {
        console.log(
          this.licenceNumeroSelected +
            " différent de " +
            selectedPrincipalLicence
        );

        this.firstuser = selectedUser;
        this.seconduser = firstUser;

        this.licenceNumeroSelected = selectedPrincipalLicence;
      }

      if (idSelected === 2) {
        this.selectSecondCard = true;
        this.nonSelectSecondCard = false;
        this.selectFirstCard = false;
        this.nonSelectFirstCard = true;
      } else {
        this.selectFirstCard = true;
        this.nonSelectFirstCard = false;
        this.selectSecondCard = false;
        this.nonSelectSecondCard = true;
      }

      if (
        this.firstuser.UTI_Utilisateurs[0].LicenceNumero <
        this.seconduser.UTI_Utilisateurs[0].LicenceNumero
      )
        this.firstUserIsOlder = true;
      else this.firstUserIsOlder = false;
    },
  },
  watch: {
    userSelected: async function() {
      let selectedUser = await this.getUtilisateurFullData(
        this.userSelected.user.id
      );
      let userfirst = this.principalUser;

      console.log("principalUser !!!!!!!", this.principalUser);
      console.log("selectedUser !!!!!!!", selectedUser);

      if (
        selectedUser.UTI_Utilisateurs[0].LicenceNumero <
        this.principalUser.UTI_Utilisateurs[0].LicenceNumero
      ) {
        this.dateNaissanceFirstUser = this.$moment(
          selectedUser.UTI_Utilisateurs[0].DN_DateNaissance
        ).format("DD/MM/YYYY");

        this.dateNaissanceUserSelected = this.$moment(
          this.principalUser.UTI_Utilisateurs[0].DN_DateNaissance
        ).format("DD/MM/YYYY");

        this.firstUserFullData = selectedUser;
        this.firstuser = selectedUser;
        this.selectedUserFullData = userfirst;
        this.seconduser = userfirst;

        this.iduserFirst = this.firstuser.UTI_Utilisateurs[0].id;
        this.iduserSecond = this.seconduser.UTI_Utilisateurs[0].id;

        console.log("this.iduserFirst", this.iduserFirst);
        console.log("this.iduserSecond", this.iduserSecond);

        this.licenceNumeroSelected =
          selectedUser.UTI_Utilisateurs[0].LicenceNumero;
      } else {
        this.firstUserFullData = userfirst;
        this.firstuser = userfirst;
        this.selectedUserFullData = selectedUser;
        this.seconduser = selectedUser;
        this.dateNaissanceUserSelected = this.$moment(
          selectedUser.UTI_Utilisateurs[0].DN_DateNaissance
        ).format("DD/MM/YYYY");

        this.iduserSecond = this.seconduser.UTI_Utilisateurs[0].id;
        this.licenceNumeroSelected = this.firstuser.UTI_Utilisateurs[0].LicenceNumero;
      }
      console.log("user selectedUserFullData-----", this.selectedUserFullData);

      this.loader = true;
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },
    userSelected: {
      type: Object,
      default: () => {
        return {};
      },
    },
    firstCurrentUser: {
      type: Object,
    },
  },
};
</script>
<style lang="scss">
.option__adhesion .v-input__slot .v-label {
  margin-bottom: 0rem !important;
}
.recap {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Grey/80 */

  color: #768aae;
}

.recap-list {
  list-style-type: circle;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  list-style-position: inside;
}

.swapIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    text-align: center;

    /* Royal/100 */

    color: #020918;
  }
}

.attention {
  color: #ff5252 !important;
  font-weight: bold;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}
.mainCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 0px;

  // position: static;
  // width: 380px;
  // height: 330px;
  // left: 30px;
  // top: 0px;

  /* Primary/White */

  background: #ffffff;
  /* Shadow/Royal */

  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25) !important;
  border-radius: 12px !important;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.cardTitle-text {
}
.mainCardTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  // position: static;
  // width: 380px;
  // height: 100px;
  // left: 0px;
  // top: 0px;

  /* Primary/Royal */

  background: #082b6e;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;

  &.not-selected {
    background: #ffffff !important;
  }
  p {
    // position: static;
    // width: 127px;
    // height: 24px;
    // left: 0px;
    // top: 0px;

    /* Body/16px Base bold */

    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Primary/White */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
}
.list_item_card {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.list_item_card_value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.slected_profil {
  p {
    /* Body/12px Micro bold */
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #082b6e;
  }
}
.unselected_profil {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #bac4d7;
  }
}
</style>
