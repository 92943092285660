<template>
  <div class="w-100">
    <v-row no-gutters>
      <v-col cols="12" class="p-3 row_first_user">
        <div class="text_design_chip py-3 d-flex alig-items-center">
          <v-chip outlined color="white" small>{{
            firstCurrentUser.UTI_Utilisateur.LicenceNumero
          }}</v-chip>
        </div>
        <div class="text_design py-3 d-flex alig-items-center">
          {{ firstCurrentUser.UTI_Utilisateur.CT_Nom }}
        </div>
        <div class="text_design py-3 d-flex alig-items-center">
          {{ firstCurrentUser.UTI_Utilisateur.CT_Prenom }}
        </div>
        <div class="text_design py-3 d-flex alig-items-center">
          {{ firstCurrentUser.UTI_Utilisateur.CT_Email }}
        </div>
        <div
          class="text_design py-3 d-flex alig-items-center"
        >
          {{ firstCurrentUser.UTI_Utilisateur.CT_TelephoneMobile || firstCurrentUser.UTI_Utilisateur.CT_TelephoneFixe || '-' }}
        </div>
        <div class="text_design py-3 d-flex alig-items-center">
          {{ $moment(firstCurrentUser.UTI_Utilisateur.DN_DateNaissance).format(
            "DD/MM/YYYY"
          )}}
        </div>
        <div class="text_design py-3 d-flex alig-items-center">
          {{ firstCurrentUser.licences[0].product.label }}
        </div>
        <div class="text_design_chip py-3 d-flex alig-items-center">
          <v-chip outlined color="white" small>{{
            firstCurrentUser.licences[0].season.id
          }}</v-chip>
        </div>
      </v-col>
    </v-row>
    <div style="background: #f0f0f0">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span v-if="panel === 0">Masquer les filtres</span>
            <span v-else>Afficher les filtres</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f0f0f0">
            <ValidateForm
              @validated="submit"
              :showFieldObl="false"
              :key="refreshForm"
            >
              <v-row class="d-flex align-items-center mt-2">
                <v-col md="3" lg="3" sm="12" class="py-0 d-block">
                  <label class="label-custom" for="">Numero de Licence</label>
                  <div class="mt-1">
                    <inputText
                      v-model="search.LicenceNumero"
                      rules="min:3"
                      type="number"
                      placeholder="N° Licence"
                    ></inputText>
                  </div>
                </v-col>

                <v-col md="3" lg="3" sm="12" class="py-0 d-block">
                  <label class="label-custom" for="">Nom</label>
                  <div class="mt-1">
                    <inputText
                      v-model="search.CT_Nom"
                      rules="min:2"
                      placeholder="Nom"
                    ></inputText>
                  </div>
                </v-col>

                <v-col md="3" lg="3" sm="12" class="py-0 d-block">
                  <label class="label-custom" for="">Prénom</label>
                  <div class="mt-1">
                    <inputText
                      v-model="search.CT_Prenom"
                      rules="min:2"
                      placeholder="Prénom"
                    ></inputText>
                  </div>
                </v-col>

                <v-col md="3" lg="3" sm="12" class="py-0 d-block">
                  <div class="mt-1">
                    <DatePicker
                      v-model="search.DateNaissance"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date de naissance"
                    ></DatePicker>
                  </div>
                </v-col>

                <!-- <v-col cols="2" lg="5" xl="2" class="pt-3">
                  <v-btn @click.prevent="erraseFilter()" outlined color="indigo"
                    >Effacer tous les filtres</v-btn
                  >
                </v-col> -->
              </v-row>

              <v-row class="pt-2">
                <v-col cols="12" class="pb-3 d-flex" xl="10" lg="9">
                  <v-btn
                    class="btn btn-primary mr-5"
                    type="submit"
                    :disabled="disabledSearch"
                    >Rechercher</v-btn
                  >
                  <v-btn outlined color="#082B6E" @click="erraseFilter" rounded
                    >Effacer tous les filtres</v-btn
                  >
                </v-col>
              </v-row>
            </ValidateForm>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div style="max-height:14vh;">
      <v-row no-gutters="">
        <v-col>
          <userList
            :areFiltersOpened="panel === 0"
            :context="context"
            :headers="headers"
            :actions="actions"
            :panel="panel"
            :items="items"
            hasItems
            @hasToReload="reloadQuery"
            :show_select="show_select"
            ref="userList"
            :key="userListKey"
            :firstCurrentUser="firstCurrentUser"
            :viewFooter="false"
            :showFusion="true"
            @modalIsOpen="openModalDuplicate"
          ></userList>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row no-gutters="">
        <v-col>
          <UserMergeDuplicateModal
            v-if="modalIsOpen"
            :modalIsOpen="modalIsOpen"
            @onClose="close"
            :context="context"
            :userSelected="user"
            :firstCurrentUser="firstCurrentUser"
          ></UserMergeDuplicateModal>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/utils/input";
import userList from "@/components/User/List/User__List.vue";
import { mapGetters, mapActions } from "vuex";
import ValidateForm from "@/components/utils/form";
import { sleep } from "@/plugins/utils";
import UserMergeDuplicateModal from "@/components/utils/modals/User/User__MergeDuplicateModal.vue";
import { getLicences } from "@/plugins/licenceService";

export default {
  components: {
    inputText,
    userList,
    ValidateForm,
    UserMergeDuplicateModal,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },

  data: () => ({
    search: {},
    saisonSelected: false,
    headers: [
      { text: "Numéro", value: "user.licenceNumero" },
      { text: "Nom", value: "user.ctNom" },
      { text: "Prénom", value: "user.ctPrenom" },
      { text: "Club", value: "structure.nomStructure", sortable: false },
      { text: "Date de naissance", value: "user.dnDateNaissance" },
      { text: "Licence", value: "product.label", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [],
    userListKey: 0,
    loading: false,
    refreshForm: 0,
    panel: 0,
    filterSaison: 2,
    idRoleAdmin: 1,
    modalIsOpen: false,
    user: {},
    licence: {},
  }),

  async created() {
    this.modalIsOpen = this.modalDuplicateOpen;
    console.log("this.firstCurrentUser", this.firstCurrentUser);
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),

    disabledSearch() {
      console.log('disabledSearch', this.search.DateNaissance)
      if (
        this.search.CT_Nom ||
        this.search.CT_Prenom ||
        this.search.CT_Telephone ||
        this.search.CT_Email ||
        this.search.LicenceNumero ||
        this.search.DateNaissance
      ) {
        return false;
      }

      return true;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "searchUtlisateur",
    ]),

    actionsContains(act) {
      let actions = this.actions.split("|");
      return actions.find((action) => action == act);
    },
    erraseFilter() {
      this.search = {};
      this.search.DateNaissance = "";
      this.UpdateDateStart(this.search.DateNaissance);
      this.items = [];
      this.userListKey += 1;
    },

    UpdateDateStart(date) {
      this.search.DateNaissance = date;
      this.refreshForm++;
    },

    async submit() {
      let response = [];
      this.$refs.userList.loading = true;
      response = await getLicences(this.getWhereClause());

      this.items = response;
      this.userListKey += 1;
      this.$refs.userList.loading = false;

      return;
    },

    async reloadQuery() {
      await sleep(1000);
      await this.submit();
    },

    getWhereClause(isExport) {
      let arrayClause = [];
      arrayClause['itemsPerPage'] = 5000;

      // Get the good wrap of user
      arrayClause['and[structure.idFederation]'] = this.context.ID_Structure;

      // ENd -- Get the good wrap of user
      if (this.search.CT_Nom) {
        arrayClause['and[user.ctNom]'] = this.search.CT_Nom;
      }

      if (this.search.CT_Prenom) {
        arrayClause['and[user.ctPrenom]'] = this.search.CT_Prenom;
      }


      if (this.search.DateNaissance) {
        arrayClause['and[user.dnDateNaissance]'] = this.search.DateNaissance;
      }

      if (this.search.CT_Telephone) {
        arrayClause['and[or][user.ctTelephoneFixe][0]'] = this.search.CT_Telephone;
        arrayClause['and[or][user.ctTelephoneMobile][1]'] = this.search.CT_Telephone;
      }

      if (this.search.CT_Email) {
        arrayClause['and[or][user.ctEmail][0]'] = this.search.CT_Email;
        arrayClause['and[or][user.ctEmail2][1]'] = this.search.CT_Email;
      }

      if (this.search.LicenceNumero) {
        arrayClause['and[user.licenceNumero]'] = this.search.LicenceNumero;
      }

      let where = {
        ...arrayClause,
      };
      // End --  Setup the field restriction
      return where;
    },

    openModalDuplicate(modalValue, item) {
      console.log("item", item);
      this.modalIsOpen = modalValue;
      this.user = item;
      console.log("this.user", this.user);
      this.$emit("modalIsOpen", this.modalIsOpen);
    },

    close() {
      // this.$emit("onClose");
      console.log("Liste search merge closeeeeeeeeeeeee");
      this.modalIsOpen = false;
    },
  },

  props: {
    context: {
      type: Object,
    },
    actions: {
      type: String,
      default: "show",
    },
    show_select: {
      type: Boolean,
      default: false,
    },
    firstCurrentUser: {
      type: Object,
    },
    modalDuplicateOpen: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.label-custom {
  font-family: "Roboto";
  margin-bottom: 0px !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  color: #90a1be;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>

<style lang="scss" scoped>
.v-input--switch {
  padding: 0;
}
.ffme-form__select {
  .v-input__control {
    min-height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
    box-shadow: none !important;
    min-height: 20px;
    border: 1px solid #dfe5f9;
    //padding: 2px 5px 1px !important;
    font-weight: 200;
    font-size: 0.8rem;
    border-radius: 0;
    height: 29px;
  }

  // CHIPS
  .v-select--chips {
    .v-input__slot {
      border: none;
    }
    .v-input__icon .v-icon {
      font-size: 2rem;
      color: #0045ab;
    }
  }
  .v-chip.v-size--small {
    font-size: 0.8rem;
    background: #0045ab;
    color: white;
    font-weight: bold;
  }

  .v-input__slot .v-select__slot {
    border: none;

    .v-label {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  }
  .v-select__selections {
    padding: 4px 5px !important;
  }
  .v-select__selection.v-select__selection--comma {
    margin: 0;
  }
  input {
    max-height: 20px;
    padding-left: 10px !important;
  }
  input[type="text"]:disabled {
    background: transparent !important;
  }
  .v-text-field__details {
    display: none;
  }
}

.filters-opened {
  height: calc(100vh - 440px);
}
.row_first_user {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px 8px;

  // position: static;
  // width: 1015px;
  // height: 56px;
  left: 0px;
  // top: 80px;
  padding-top: 25px;

  /* Primary/Royal */

  background: #082b6e;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}
.text_design {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  //padding-left: 10px;

  /* Primary/White */

  color: #ffffff;
}
// .text_design_chip {
//   padding-bottom: 10px !important;
//   padding-left: 10px;
// }
</style>
